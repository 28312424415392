import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import Config from "../Config";

export default class Repository {
	public static GET = 'GET';
	public static POST = 'POST';
	public static PUT = 'PUT';
	public static DELETE = 'DELETE';

	@Autowire(Config) config!: Config;

	constructor() {
	}

	private _handleError: (xhr: any, status: any, error: any) => any = function (xhr: any, status: any, error: any) {
	};

	set handleError(value: (xhr: any, status: any, error: any) => any) {
		this._handleError = value;
	}

	protected request(url: string, data: any = null, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', specificParameters: any = {}): any {
		let self = this;
		let request: any = {
			url: url,
			method: method,
			headers: {}
		};

		if (method == 'GET') {
			request.data = undefined;
		} else {
			request.data = typeof data !== 'string' ? JSON.stringify(data) : data;
			request.contentType = "application/json; charset=utf-8";
			request.dataType = "json";
		}

		let responseType : 'json'|'arraybuffer' = 'json';

		for (let key in specificParameters) {
			if (key === 'arraybuffer' && specificParameters[key] === true) {
				responseType = 'arraybuffer';
			} else
				request[key] = specificParameters[key];
		}

		return new Promise(function (resolve, reject) {
			let xhr = new XMLHttpRequest();
			xhr.responseType = responseType;

			xhr.onload = function () {
				if(xhr.responseType === 'json') {
					(<any>xhr)['responseJSON'] = xhr.response;
				}
				if(xhr.status >= 200 && xhr.status <= 299) {
					resolve(xhr.response);
				} else {
					reject(xhr)
				}
			};
			xhr.onerror = function () {
				if(xhr.responseType === 'json') {
					(<any>xhr)['responseJSON'] = xhr.response;
				}
				reject(xhr);
			};

			xhr.open(request.method, request.url, true);
			for (let header in request.headers) {
				xhr.setRequestHeader(header, request.headers[header]);
			}
			xhr.send(request.data);
		});
	};

}
