export default class Config{

	apiUrl : string = 'http://127.0.0.1:9130/';
	version : string = 'DEVELOP';

	public constructor() {
		if(window.location.hostname.split('.').length === 4 && parseInt('0'+window.location.hostname.split('.')[0]) !== 0){// ip address
			this.apiUrl = window.location.protocol+'//'+window.location.hostname+':9130/';
		}else{
			this.apiUrl = 'https://api.ateliercariboo.com/';
		}

	}

	static fetch() : Promise<Config>{
		return Promise.resolve(new Config());
	}

}
