
type EventMap = Record<string, any>;

type EventKey<T extends EventMap> = string & keyof T;
type EventReceiver<T> = (params: T) => void;

interface EventEmitterInterface<T extends EventMap> {
	on<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>): void;
	off<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>): void;
	emit<K extends EventKey<T>>(eventName: K, params: T[K]): void;
}

function EventEmitterFactory<T extends EventMap>(): EventEmitterInterface<T> {
	const listeners: {
		[K in keyof EventMap]?: Array<(p: EventMap[K]) => void>;
	} = {};

	return {
		on(key, fn) {
			listeners[key] = (listeners[key] || []).concat(fn);
		},
		off(key, fn) {
			listeners[key] = (listeners[key] || []).filter(f => f !== fn);
		},
		emit(key, data) {
			(listeners[key] || []).forEach(function(fn) {
				fn(data);
			});
		},
	};
}

export class EventEmitter<T extends EventMap> implements EventEmitterInterface<T> {
	private emitter = EventEmitterFactory();
	on<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>) {
		this.emitter.on(eventName, fn);
	}

	off<K extends EventKey<T>>(eventName: K, fn: EventReceiver<T[K]>) {
		this.emitter.off(eventName, fn);
	}

	emit<K extends EventKey<T>>(eventName: K, params: T[K]) {
		this.emitter.emit(eventName, params);
	}
}
