export enum EstablishmentRight {
	ADMIN = 'ADMIN',
	FITTING = 'FITTING',
	MANAGEMENT = 'MANAGEMENT',
	MANUFACTURING = 'MANUFACTURING',
}

export type Establishment = {
	uid:string,
	name:string,
	creationDate:string,
	rights:EstablishmentRight[],
}

export type EstablishmentPartner = {
	establishmentUid:string,
	profileUid:string,
}

export function hasEstablishmentAccess(establishment : Establishment, right : EstablishmentRight){
	return establishment.rights.includes(right);
}
