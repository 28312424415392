import {VueClass, VueMounted, VueVar} from "horizon-js-front-sdk/lib/VueAnnotate";
import Vue from "vue";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import {Router, RouterEventListener, RouterNewRouteTriggerMethod, RouterRoute} from "../libs/Router";
import {EstablishmentRepository} from "../repositories/EstablishmentRepository";
import {Establishment, EstablishmentRight, hasEstablishmentAccess} from "../classes/Establishment";
import {AuthCenter} from "../repositories/AuthCenter";

@VueClass()
export class TopControlsVue extends Vue{
	@Autowire(EstablishmentRepository) establishmentRepository !: EstablishmentRepository;
	@Autowire(AuthCenter) authCenter !: AuthCenter;
	@Autowire(Router) router !: Router;

	@VueVar() establishmentUid : string = '';
	@VueVar() establishment : Establishment|null = null;
	@VueVar() rightLabel : string = '';
	@VueVar() display : boolean = true;
	private establishments : Establishment[] = [];
	private nbRows = 1;

	constructor(container : string, vueParams : any = null) {
		super(vueParams);
	}

	@VueMounted()
	mounted(){
		this.router.addListener(new class implements RouterEventListener {
			private router !: Router;
			private topControls : TopControlsVue;

			constructor(topControls : TopControlsVue) {
				this.topControls = topControls;
			}

			onJsLoadingError(error: any, oldRoute: RouterRoute | null, newRoute: RouterRoute | null): void {
			}

			onJsLoadingSuccess(newRoute: RouterRoute, direction: RouterNewRouteTriggerMethod): void {
				if(window.location.pathname.startsWith('/establishment/')){
					let uid = this.router.lastRouteRegexMatches?.[1] ?? '';
					console.log(uid);
					if(uid) this.topControls.setCurrentEstablishment(uid);
				}
			}

			onNoRouteFound(error: any, oldRoute: RouterRoute | null, newRoute: string): void {
			}

			onPreLoading(newRoute: RouterRoute, direction: RouterNewRouteTriggerMethod): void {
			}

			onUnloading(oldRoute: RouterRoute, direction: RouterNewRouteTriggerMethod): void {
			}

			onUnloadingFinished(oldRoute: RouterRoute, direction: RouterNewRouteTriggerMethod): void {
			}

			setRouter(router: Router): void {
				this.router = router;
			}
		}(this));

		this.authCenter.on('connected', ()=>{
			this.onConnected();
		});

		if(this.authCenter.isConnected())
			this.onConnected();

		this.setRows();
	}

	onConnected(){
		this.establishmentRepository.getEstablishmentLinks().then((establishments)=>{
			this.establishments = establishments;
			if(this.establishmentUid) this.setCurrentEstablishment(this.establishmentUid);
		});
	}

	setCurrentEstablishment(uid : string){
		this.establishmentUid = uid;
		for(let e of this.establishments){
			if(e.uid === uid) {
				this.establishment = e;
				this.rightLabel = e.name;
			}
		}
		this.$forceUpdate();
	}

	hasEstablishmentAccess(right : EstablishmentRight){
		if(this.establishment === null) return false;
		return hasEstablishmentAccess(this.establishment, right);
	}

	private static instance : TopControlsVue|null = null;
	static getInstance() : TopControlsVue{
		if(this.instance === null)
			this.instance = new TopControlsVue('#topControls');
		return this.instance;
	}

	show(){
		this.display = true;
		this.setRows(this.nbRows);
	}
	hide(){
		this.display = false;
		this.setRows(0, false);
	}

	setRows(nbRows : number = 1, save : boolean = true){
		if(save)
			this.nbRows = nbRows;

		this.display = nbRows !== 0;

		// TODO nbRows = 0 => show only the icon
		const mainContainer : HTMLElement = document.getElementById('mainPageContainer') as HTMLElement;
		let heightInPx = nbRows * 36;
		mainContainer.style.height = 'calc(100% - '+heightInPx+'px)';
		mainContainer.style.top = heightInPx+'px';
	}

	setTopMargin(margin : 'default'|'none'){
		const mainContainer : HTMLElement = document.getElementById('mainPageContainer') as HTMLElement;
		if(margin === 'default')
			mainContainer.classList.remove('noTopMargin');
		else if(margin === 'none')
			mainContainer.classList.add('noTopMargin');
	}

}


