import {Establishment} from "../classes/Establishment";
import {CacheRepository} from "./CacheRepository";

export class EstablishmentRepository extends CacheRepository{

	getEstablishmentLinks() : Promise<Establishment[]>{
		return this.cachedRequest(this.config.apiUrl+'v1/Establishments/', null, 'GET', true);
	}

}
