import {AuthedRepository} from "./AuthedRepository";

export class CacheRepository extends AuthedRepository{
	private cachedRequestData : {[signature : string] : Promise<any>} = {};

	protected cachedRequest(url: string, data: any = null, method: "GET" | "POST" | "PUT" | "DELETE" = 'GET', requireAuth: boolean = true, specificParameters: any = {}): any {
		let signature = this.getSignatureForRequest(url, method);
		if(signature === null){
			return super.request(url, data, method, requireAuth, specificParameters);
		}else{
			if(typeof this.cachedRequestData[signature] === 'undefined')
				this.cachedRequestData[signature] = super.request(url, data, method, requireAuth, specificParameters);

			return this.cachedRequestData[signature];
		}
	}

	private getSignatureForRequest(url : string, method : "GET" | "POST" | "PUT" | "DELETE") : string|null{
		if(method !== 'GET') return null;

		return method+':'+url;
	}
}
