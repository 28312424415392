import {Logger} from "horizon-js-front-sdk/lib/Logger";
import {DestructableView} from "horizon-js-front-sdk/lib/DestructableView";

export type RouterRoute = {
	location?:string,regex?:RegExp,loader:(()=>Promise<RouterRouteModule<any>|any>),
};

export type RouterNewRouteTriggerMethod = 'link'|'browser';

export interface RouterEventListener{
	setRouter(router : Router) : void;
	onUnloading(oldRoute: RouterRoute, direction : RouterNewRouteTriggerMethod) : void;
	onUnloadingFinished(oldRoute: RouterRoute, direction : RouterNewRouteTriggerMethod) : void;
	onPreLoading(newRoute: RouterRoute, direction : RouterNewRouteTriggerMethod) : void;
	onJsLoadingSuccess(newRoute: RouterRoute, direction : RouterNewRouteTriggerMethod) : void;
	onNoRouteFound(error : any, oldRoute: RouterRoute|null, newRoute: string) : void;
	onJsLoadingError(error : any, oldRoute: RouterRoute|null, newRoute: RouterRoute|null) : void;
}
export interface RouterHook{
	beforeOnLoad(state : any) : any;
	afterOnUnload(state : any) : any;
}

export class RouterOldLoadingListener implements RouterEventListener{
	private router !: Router;

	setRouter(router: Router): void {
		this.router = router;
	}

	onNoRouteFound(error : any, oldRoute: RouterRoute|null, newRoute: string): void {
		let e = document.getElementById('pageLoading');
		if(e)e.style.display = 'none';
	}

	onJsLoadingError(error : any, oldRoute: RouterRoute|null, newRoute: RouterRoute|null): void {
		console.log(error);
		console.error(error);
		let e = document.getElementById('pageLoading');
		if(e)e.style.display = 'none';
	}

	onJsLoadingSuccess(newRoute: RouterRoute): void {
		let e = document.getElementById('pageLoading');
		if(e)e.style.display = 'none';

		this.router.getContainerElement().style.display = 'block';
	}

	onPreLoading(oldRoute: RouterRoute, direction : RouterNewRouteTriggerMethod): void {
		let e = document.getElementById('pageLoading');
		if(e)e.style.display = 'block';
	}

	onUnloadingFinished(oldRoute: RouterRoute): void {}

	onUnloading(oldRoute: RouterRoute|null): void {
	}
}

export type RouterHashbangConfig = {
	prefix:string,
	registerGlobalInterceptor:boolean,
}

export type RouterUrlConfig = {
	prefix:string,
	suffixes:string[],
	registerGlobalInterceptor:boolean,
}

export type RouterUrlMode = {
	type:'URL',
	config:RouterUrlConfig,
}

export type RouterHashbangMode = {
	type:'HASHBANG',
	config:RouterHashbangConfig,
}

export type RouterMode = RouterUrlMode|RouterHashbangMode;

export interface RouterRouteModuleLoader<TState>{
	load(router : Router, state : TState) : void|Promise<void>;
	unload() : void|Promise<TState|void>;
}

export type RouterRouteModule<TState> = RouterRouteModuleLoader<TState>&{
	default?:CallableFunction,
	loader?:()=>RouterRouteModuleLoader<TState>,
};

export type RouterEvent = {
	type:string,
}
export type RouterNewRouteEvent = RouterEvent&{
	type:'new_route',
	matchedRoute:RouterRoute|null,
}
export type RouterNewRouteEventListener = ((event : RouterNewRouteEvent) => void);

class GenericModuleLoaderWrapper<TState>{
	private module : RouterRouteModuleLoader<TState>;
	private router : Router;

	constructor(module : RouterRouteModuleLoader<TState>, router : Router) {
		this.module = module;
		this.router = router;
	}

	isCompatible(){
		return this.module.load || this.module.unload;
	}

	load(state : any|undefined = undefined) : Promise<void>{
		let r : any = undefined;
		if(this.module.load){
			r = this.module.load(this.router, state);
		}
		if(r instanceof Promise)
			return r;
		return Promise.resolve(r);
	}

	unload() : Promise<any>{
		let state : any = undefined;
		if(this.module.unload){
			state = this.module.unload();
		}
		if(state instanceof Promise)
			return state;
		return Promise.resolve(state);
	}

}

export class Router<TRouterRoute extends RouterRoute = RouterRoute>{
	public static readonly FAKE_STATE_BLOCK_NAVIGATION = '__BLOCK_NAVIGATION__';

	protected mode : RouterMode = {
		type:'HASHBANG',
		config:{
			prefix:'#',
			registerGlobalInterceptor:true,
		}
	};

	protected currentPage: string | null = null;
	protected currentRoute : TRouterRoute|null = null;
	protected currentRouteModule : GenericModuleLoaderWrapper<any>|undefined = undefined;
	protected routerBaseContentRelativity = './pages/';
	protected contentContainerId : string = 'page';
	protected onLoadingListeners : RouterEventListener[] = [];
	protected hooks : RouterHook[] = [];
	protected pageContainer : HTMLElement;
	protected globalLinksInterceptor : any = null;

	protected routes : TRouterRoute[] = [];

	private _lastRouteRegexMatches : RegExpExecArray|null = null;

	private _webpackModulesPerRoute : {[key : string] : string[]} = {};
	private _scrollToTop: boolean = false;
	private _disableRouteTriggerIfIdentical: boolean = false;

	constructor(routerBaseContentRelativity : string|null = null, mode:RouterMode|null=null, contentContainerId:string|null = null, onLoadingListeners:RouterEventListener[]|null=null) {
		if(mode) this.setMode(mode);
		else this.setMode(this.mode);

		if(contentContainerId) this.contentContainerId = contentContainerId;
		if(routerBaseContentRelativity) this.routerBaseContentRelativity = routerBaseContentRelativity;

		for(let listener of (onLoadingListeners ?? [new RouterOldLoadingListener()])){
			this.addListener(listener);
		}

		this.pageContainer = document.body;
		let userContentContainer = document.getElementById(this.contentContainerId);
		if(userContentContainer) this.pageContainer = userContentContainer;

		(<any>window).horizonMainRouter = this;
	}

	set scrollToTop(value: boolean) {
		this._scrollToTop = value;
	}

	static getMainRouter(): Router | null {
		return (<any>window).horizonMainRouter ?? null;
	}

	disableRouteTriggerIfIdentical(state : boolean = true){
		this._disableRouteTriggerIfIdentical = state;
	}

	protected registerGlobalLinksInterceptor(){
		if(this.globalLinksInterceptor === null) {
			this.globalLinksInterceptor = (e : Event) => {
				if(e.target && e.target instanceof HTMLElement){
					let elementToCheck : HTMLElement|null = e.target;
					while(elementToCheck){
						if (elementToCheck.onclick !== null) {
							break;
						}
						if (elementToCheck instanceof HTMLAnchorElement
							&& elementToCheck.href
							&& elementToCheck.href.trim() !== ''
							&& elementToCheck.target !== '_blank'
						) {
							this.handleClickOnAnchorElement(elementToCheck, 'link');
							e.preventDefault();
							e.stopImmediatePropagation();
							return false;
						}
						elementToCheck = elementToCheck.parentElement;
					}
				}
			};
			document.addEventListener(`click`, this.globalLinksInterceptor);
		}
	}
	protected handleClickOnAnchorElement(element : HTMLAnchorElement, triggerMethod : RouterNewRouteTriggerMethod){
		return this.changePage(Router.extractPageFromUrl(this.mode, new URL(element.href)), undefined, triggerMethod).then(()=>{
			window.history.pushState(null, '', element.href ?? '');
		});
	}

	protected registerListenerPopState(){
		window.onpopstate = (event : PopStateEvent)=>{
			// if the block state is detected, we need to send the user back to the "real" page
			// if the page reload on same route is disabled, nothing will happen, otherwise it will reload
			if(event.state === Router.FAKE_STATE_BLOCK_NAVIGATION){
				window.history.go(1);
			}else
				return this.updateCurrentPageFromCurrentLocation(event.state, 'browser');
		}
	}

	protected unregisterListenerPopState(){
		window.onpopstate = null;
	}

	protected unregisterGlobalLinksInterceptor(){
		if(this.globalLinksInterceptor !== null) {
			document.removeEventListener(`click`, this.globalLinksInterceptor);
			this.globalLinksInterceptor = null;
		}
	}

	protected registerHashchange(){
		window.onhashchange = ()=>{
			return this.updateCurrentPageFromCurrentLocation(undefined);
		};
	}

	protected unregisterHashchange(){
		window.onhashchange = null;
	}

	/**
	 * Set current routing mechanism : either hashbang (old norm) or new one (dynamically changing the url)
	 * @param mode
	 */
	setMode(mode : RouterMode) : this{
		if(this.mode.type === 'URL'){
			this.unregisterGlobalLinksInterceptor();
			this.unregisterListenerPopState();
		}
		if(this.mode.type === 'HASHBANG') this.unregisterHashchange();

		this.mode = mode;
		if(this.mode.type === 'URL' && this.mode.config.registerGlobalInterceptor){
			this.registerGlobalLinksInterceptor();
			this.registerListenerPopState();
		}
		if(this.mode.type === 'HASHBANG' && this.mode.config.registerGlobalInterceptor) this.registerHashchange();

		return this;
	}

	/**
	 * Add an event listener
	 * @param listener
	 */
	addListener(listener : RouterEventListener) : this{
		this.onLoadingListeners.push(listener);
		listener.setRouter(this);
		return this;
	}
	addHook(hook : RouterHook){
		this.hooks.push(hook);
	}

	/**
	 * Get the current page from the url or fallback on index
	 * @returns string
	 */
	static extractPageFromUrl(mode : RouterMode, url : URL = new URL(window.location.toString())) : string{
		if(mode.type === 'HASHBANG') {
			let page = 'index';
			if (url.hash.indexOf(mode.config.prefix) != -1) {
				page =  url.hash.substr(mode.config.prefix.length);
			}
			if (page.indexOf('?') != -1) {
				page = page.substr(0, page.indexOf('?'));
			}
			return page;
		} else if(mode.type === 'URL') {
			let page = 'index';
			if(url.pathname.startsWith(mode.config.prefix))
				page =  url.pathname.substr(mode.config.prefix.length);
			for(let suffix of mode.config.suffixes)
				if(page.endsWith(suffix)){
					page = page.substr(0, page.length-suffix.length);
					break;
				}
			if(page === '') page = 'index';
			return page;
		}else
			return 'index';
	}

	/**
	 * change the current page accordingly to the current URL
	 */
	updateCurrentPageFromCurrentLocation(state : any = '__INITIAL__', triggerMethod : RouterNewRouteTriggerMethod = 'link'){
		if(state === '__INITIAL__') state = window.history.state;
		return this.changePage(Router.extractPageFromUrl(this.mode), state, triggerMethod);
	}

	/**
	 * @deprecated
	 */
	changePageFromHash(){
		return this.updateCurrentPageFromCurrentLocation(undefined);
	}

	/**
	 * @param route new route to add to router
	 */
	addRoute(route : TRouterRoute) : this{
		this.routes.push(route);
		return this;
	}

	previous(){
		window.history.go(-1);
	}
	next(){
		window.history.go(1);
	}

	/**
	 * "Clear" the browser navigation history by injecting a "fake" page in order to stop any navigation before this point.
	 * This trick is required as it's not possible to clear the real navigation history
	 */
	clearHistory(){
		let currentUrl = window.location.href;
		console.debug('Router>clear history with fake URL', currentUrl)
		window.history.replaceState(Router.FAKE_STATE_BLOCK_NAVIGATION, '', currentUrl);
		// inject the current page to be able to return to it and not being blocked
		window.history.pushState(null, '', currentUrl);
	}

	/**
	 * @param routes new routes to add to router
	 */
	addRoutes(routes : TRouterRoute[]) : this{
		Array.prototype.push.apply(this.routes, routes);
		return this;
	}

	getRoutes(): TRouterRoute[] {
		return this.routes;
	}

	/**
	 * Obtain the current URL regex matches
	 */
	get lastRouteRegexMatches() : RegExpExecArray|null{
		return this._lastRouteRegexMatches;
	}

	protected searchRouteWithLocation(location : string) : TRouterRoute|null{
		for(let route of this.routes){
			if(route.location && location === route.location){
				return route;
			}else if(route.regex && location.match(route.regex)){
				return route;
			}
		}
		return null;
	}

	/**
	 * Change the current page by loading the new content in the same page,
	 * Update the browser history
	 * @param {string} newPage new page path to load
	 * @param {any} state to send to the route
	 * @param triggerMethod
	 */
	changePage(newPage: string, state : any = undefined, triggerMethod : RouterNewRouteTriggerMethod = 'link') : Promise<void> {
		let oldPage = this.currentPage;
		let oldRoute = this.currentRoute;
		let oldRouteModule = this.currentRouteModule;

		let newRoute = this.searchRouteWithLocation(newPage);

		if(this._disableRouteTriggerIfIdentical && newRoute === oldRoute){
			console.debug('Router> intercept same page reload');
			return Promise.resolve();
		}

		if(newRoute && newRoute.regex){
			this._lastRouteRegexMatches = newRoute.regex.exec(newPage);
		}else{
			this._lastRouteRegexMatches = null;
		}
		if(typeof (<any>window).horizonRouter === "undefined")(<any>window).horizonRouter = {};
		(<any>window).horizonRouter.lastRouteRegexMatches = this.lastRouteRegexMatches;


		Logger.info(this, 'Changing page to {newPage} from {oldPage} with controller {controller}', {
			newPage: newPage,
			oldPage: oldPage
		});

		let promiseDestruct: Promise<void> = Promise.resolve();
		if(oldRoute) {
			for (let listener of this.onLoadingListeners) {
				listener.onUnloading(oldRoute, triggerMethod);
			}
		}

		if(oldRouteModule && oldRouteModule.isCompatible() && triggerMethod === 'link'){
			// in case of navigation with a browser, the URL will already have been replaced, so we cant store any data by replacing the date
			promiseDestruct = promiseDestruct.then(()=>{
				if(oldRouteModule)
					return oldRouteModule.unload().then((state)=>{
						if(state) {
							let hookedState = state;
							for(let hook of this.hooks.slice().reverse())
								hookedState = hook.afterOnUnload(hookedState);

							window.history.replaceState(hookedState, '');
						}
					});
			});
		}

		promiseDestruct = promiseDestruct.then(()=>{
			let currentView = DestructableView.getCurrentAppView();
			if (currentView !== null) {
				if (typeof (<any>currentView)['$destroy'] !== 'undefined')
					(<any>currentView)['$destroy']();
				if (currentView.destruct) {
					promiseDestruct = currentView.destruct();
				}
			}
		});


		if(this._scrollToTop) {
			window.scrollTo(0,0);
		}

		//we wait the promise of destruction in case of something that could take time
		return promiseDestruct.then(()=>{
			// remove all possible links to the old view
			DestructableView.setCurrentAppView(null);

			this.currentPage = newPage;
			this.currentRoute = newRoute;

			// unload webpack modules for old route to properly allow fresh loading
			if(oldRouteModule && !oldRouteModule.isCompatible() && oldRoute) {
				if (oldRoute.location && this._webpackModulesPerRoute[oldRoute.location])
					this.unloadWebpackModules(this._webpackModulesPerRoute[oldRoute.location]);
				else if (oldRoute.regex && this._webpackModulesPerRoute[oldRoute.regex.source])
					this.unloadWebpackModules(this._webpackModulesPerRoute[oldRoute.regex.source]);
			}

			if(oldRoute) {
				for (let listener of this.onLoadingListeners) {
					listener.onUnloadingFinished(oldRoute, triggerMethod);
				}
			}

			if(newRoute) {
				for (let listener of this.onLoadingListeners) {
					listener.onPreLoading(newRoute, triggerMethod);
				}
			}

			Logger.debug(self, 'Changing to page '+this.currentPage);

			let eventNewRoute : RouterNewRouteEvent = {
				type:'new_route',
				matchedRoute:newRoute,
			};
			this.dispatchEvent(eventNewRoute);

			if(newRoute === null){
				this.injectError404Tags();
				for(let listener of this.onLoadingListeners){
					listener.onNoRouteFound('no_route_found', oldRoute, newPage);
				}
				this.currentRouteModule = undefined;
			}else {
				this.removeError404Tags();

				let webpackModulesBeforeLoad = this.getCurrentlyLoadedModulesInWebpack();

				newRoute.loader().then((loadedModule : RouterRouteModule<any>) => {
					if(loadedModule.loader && typeof loadedModule.loader === 'function')
						return loadedModule.loader();
					return loadedModule;
				}).then((loaderInstance)=>{
					this.currentRouteModule = new GenericModuleLoaderWrapper(loaderInstance, this);
					// inject empty state to not use it multiple times/have weird states with page refresh
					window.history.replaceState(null, '');
					let hookedState = state;
					for(let hook of this.hooks)
						hookedState = hook.beforeOnLoad(hookedState);

					return this.currentRouteModule.load(hookedState);
				}).then(()=>{
					let webpackModulesAfterLoad = this.getCurrentlyLoadedModulesInWebpack();
					let modulesLoadedForRoute = this.findModulesMismatchBetweenTables(webpackModulesBeforeLoad, webpackModulesAfterLoad);

					if(newRoute && newRoute.location)
						this._webpackModulesPerRoute[newRoute.location] = modulesLoadedForRoute;
					else if(newRoute && newRoute.regex)
						this._webpackModulesPerRoute[newRoute.regex.source] = modulesLoadedForRoute;

					if(newRoute) {
						for (let listener of this.onLoadingListeners) {
							listener.onJsLoadingSuccess(newRoute, triggerMethod);
						}
					}
				}).catch((error) => {
					console.error(error);
					for (let listener of this.onLoadingListeners) {
						listener.onJsLoadingError(error, oldRoute, newRoute);
					}
				});
			}
		});
	}

	protected getCurrentlyLoadedModulesInWebpack() : string[]{
		return Object.keys((<any>require).cache);
	}

	protected findModulesMismatchBetweenTables(before : string[], after : string[]) : string[]{
		let diffMods : string[] = [];
		for(let mod of after){
			if(before.indexOf(mod) === -1) {
				diffMods.push(mod);
			}
		}
		return diffMods;
	}

	/**
	 * Manually inject page HTML inside the router main element
	 * @param html
	 */
	public injectPageHtml(html : string){
		this.pageContainer.innerHTML = html;
	}

	protected getMetas(metaName : string) {
		const metas = document.getElementsByTagName('meta');
		let matchingMetas : HTMLElement[] = [];
		for (let i = 0; i < metas.length; i++) {
			if (metas[i].getAttribute('name') === metaName) {
				matchingMetas.push(metas[i]);
			}
		}

		return matchingMetas;
	}

	public injectError404Tags(){
		let metas = this.getMetas('robots');
		if(metas.length){
			metas[0].setAttribute('content', 'noindex');
			for(let i = 1; i < metas.length; ++i)
				metas[i].remove();
		}else{
			let meta = document.createElement('meta');
			meta.name = "robots";
			meta.content = "noindex";
			document.getElementsByTagName('head')[0].appendChild(meta);
		}
	}
	public removeError404Tags(){
		let metas = this.getMetas('robots');
		for(let i = 0; i < metas.length; ++i)
			metas[i].remove();
	}

	/**
	 * Unload modules from webpack to properly allow a new loading
	 * @param modules
	 */
	unloadWebpackModules(modules: string[]) {
		for(let module of modules)
			try {
				delete (<any>require).cache[module];
			} catch(err) {
				console.log(err);
			}
	}


	private eventListeners : {[eventName : string] : (RouterNewRouteEventListener)[]} = {};
	protected dispatchEvent(event : RouterNewRouteEvent){
		if(this.eventListeners[event.type]){
			for(let listener of this.eventListeners[event.type])
				listener(event);
		}
	}

	addEventListener(event: 'new_route', listener: RouterNewRouteEventListener|null): this{
		if(listener) {
			if(typeof this.eventListeners[event] === 'undefined') this.eventListeners[event] = [];
			this.eventListeners[event].push(listener);
		}

		return this;
	}

	getContainerElement(){
		return this.pageContainer;
	}
}
