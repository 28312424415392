import {EventEmitter} from "../libs/events/EventsGenerator";

export type AuthToken = {
	type: string,
	token: string,
	expiresIn: number,
	expiresAt: number,
}

export type AuthCenterEventConnected = {};
export type AuthCenterEventDisconnected = {};

export class AuthCenter extends EventEmitter<{connected:AuthCenterEventConnected, disconnected:AuthCenterEventDisconnected}>{
	isConnected(): boolean {
		return this.getAuthToken() !== null;
	}
	getAuthToken() : AuthToken|null{
		let storedAuth = window.localStorage.getItem('auth');
		if (storedAuth) {
			let decoded : AuthToken = JSON.parse(storedAuth);
			if(decoded !== null && decoded.expiresAt > (new Date()).getTime()/1000){
				return decoded;
			}
			window.localStorage.removeItem('auth');
			this.emit('disconnected', {});
		}
		return null;
	}

	storeConnectionToken(authToken : AuthToken) {
		let wasAuth = this.isConnected();
		window.localStorage.setItem('auth', JSON.stringify(authToken));
		if(!wasAuth){
			this.emitConnected();
		}
	}

	emitConnected(){
		this.emit('connected', {});
	}

	getAuthHeader() : string{
		let token = this.getAuthToken();
		if(token){
			return token.type+' '+token.token;
		}
		return '';
	}

}
