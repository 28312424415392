import Config from "./Config";
import "jquery";
import "../fomantic/dist/semantic.min.css";
import "../fomantic/dist/semantic.min";
import {Logger} from "horizon-js-front-sdk/lib/Logger";
import {DependencyInjector, DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import {Router, RouterEventListener, RouterNewRouteTriggerMethod, RouterRoute} from "./libs/Router";
// @ts-ignore
import './assets/css/app.css';
import '../fomantic/dist/semantic.css';
import {TopControlsVue} from "./pages/TopControls";
// import Cleave from "vue-cleave-component";

//setting logger on info
Logger.level = Logger.INFO;
let injector: DependencyInjector = DependencyInjectorInstance();

function initApp(config : Config) {
	injector.register(Config, config);


	let xDomainParameter: any = {};
/*
	let plDomain = config.invoiceApi_serviceUrl;
	if (plDomain) {
		let nddWithoutSlashAtTheEnd: string = '';
		if (plDomain.charAt(plDomain.length - 1) === '/') {
			nddWithoutSlashAtTheEnd = plDomain.substring(0, plDomain.length - 1);
		}

		xDomainParameter[nddWithoutSlashAtTheEnd] = "/api/cors";
	}
	xdomain.slaves(xDomainParameter);
*/

	let storedVersion = window.localStorage.getItem('version');
	if (storedVersion !== null) {
		if (storedVersion !== config.version) {
			window.localStorage.setItem('version', config.version);
			window.location.reload();
		}
	} else {
		window.localStorage.setItem('version', config.version);
	}

	// let appState: AppState = new AppState();
	// injector.register(AppState, appState);

	// Vue.use(Cleave);

	let router = new Router('/pages/', {
		type: 'URL',
		config: {
			suffixes: ['.html'],
			prefix: '/',
			registerGlobalInterceptor: true
		}
	}, 'mainPageContainer');
	router.addListener(new class implements RouterEventListener {
		onJsLoadingError(err: any, oldRoute: RouterRoute | null, newRoute: RouterRoute | null): void {
			if (err.error && typeof err.error === "object" && "message" in err.error && typeof err.error.message === "string") {
				if (
					err.error.message.toLowerCase().indexOf("failed to fetch dynamically imported module") !== -1 || // chrome, edge
					err.error.message.toLowerCase().indexOf("error loading a dynamically imported module") !== -1 || // firefox
					err.error.message.toLowerCase().indexOf("importing a module script failed") !== -1 // safari
				) {
					const sessionKey = "last-chunk-error-reload";

					let lastReload: {nth: number; timestamp: number} = {nth: 0, timestamp: Date.now()};
					let rawLastReload = sessionStorage.getItem(sessionKey);
					if (rawLastReload) {
						lastReload = JSON.parse(rawLastReload);
						if (lastReload.nth > 2 && Date.now() - lastReload.timestamp < 5 * 60 * 1000) {
							// Reload doesn't seem to fix the problem, abort
							return err;
						}
					}

					lastReload.nth++;
					lastReload.timestamp = Date.now();
					sessionStorage.setItem(sessionKey, JSON.stringify(lastReload));

					window.location.reload();
				}
			}
		}


		onJsLoadingSuccess(newRoute: RouterRoute, direction: RouterNewRouteTriggerMethod): void {
			console.log('success')
		}

		onNoRouteFound(error: any, oldRoute: RouterRoute | null, newRoute: string): void {
			console.log('no route');
		}

		onPreLoading(newRoute: RouterRoute, direction: RouterNewRouteTriggerMethod): void {
		}

		onUnloading(oldRoute: RouterRoute, direction: RouterNewRouteTriggerMethod): void {
		}

		onUnloadingFinished(oldRoute: RouterRoute, direction: RouterNewRouteTriggerMethod): void {
		}

		setRouter(router: Router): void {
		}

	})

	let uuidRegex = /([0-9a-f]{8}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{12})/;
	let uuidRegexGroup = '([0-9a-f]{8}\\-[0-9a-f]{4}\\-[0-9a-f]{4}\\-[0-9a-f]{4}\\-[0-9a-f]{12})';

	router.addRoute({location: 'index', loader: () => import('./pages/index')});
	router.addRoute({location: 'login', loader: () => import('./pages/login')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/$'), loader: () => import('./pages/establishmentIndex')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/details$'), loader: () => import('./pages/establishment/details')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/manufacturing/orders/$'), loader: () => import('./pages/manufacturing/orders')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/manufacturing/orders/new$'), loader: () => import('./pages/manufacturing/newOrder')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/manufacturing/orders/'+uuidRegexGroup+'/$'), loader: () => import('./pages/manufacturing/order')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/virtual-fitting/$'), loader: () => import('./pages/virtualFitting')});

	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/management/customers/$'), loader: () => import('./pages/management/customers')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/management/customers/new$'), loader: () => import('./pages/management/editCustomer')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/management/customers/'+uuidRegexGroup+'/$'), loader: () => import('./pages/management/editCustomer')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/management/customers/'+uuidRegexGroup+'/orders/new$'), loader: () => import('./pages/management/editCustomerOrder')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/management/customers/'+uuidRegexGroup+'/orders/'+uuidRegexGroup+'/$'), loader: () => import('./pages/management/editCustomerOrder')});
	router.addRoute({regex:  new RegExp('establishment/'+uuidRegexGroup+'/management/references/$'), loader: () => import('./pages/management/references')});


	router.addRoute({regex:  new RegExp('admin/$'), loader: () => import('./pages/admin/index')});
	router.addRoute({regex:  new RegExp('admin/designer$'), loader: () => import('./pages/admin/designer')});

	DependencyInjectorInstance().register(Router, router);

	TopControlsVue.getInstance();

	router.updateCurrentPageFromCurrentLocation();

	// if(AuthenticationManager.isConnected()) {
	// 	new NavbarView('#navbar');
	// } else {
	// 	router.changePage('auth')
	// }
}

Config.fetch().then(initApp);
