import Repository from "./Repository";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import {AuthCenter} from "./AuthCenter";


export type InterWindows_EventBase = {
	event:string,
};
export type Message_LoginSuccess = InterWindows_EventBase&{
	event:'loginSuccess'
}
export type InterWindows_Event = Message_LoginSuccess;

export class AuthedRepository extends Repository{
	@Autowire(AuthCenter) authCenter!: AuthCenter;
	private pendingAuthPromise : Promise<void>|null = null;
	private pendingAuthPromiseResolve : Function|null = null;

	windowMessageListener = (e : MessageEvent)=>{
		let data: InterWindows_Event = e.data;
		if (data.event === 'loginSuccess') {
			this.authCenter.emitConnected();
			if(this.pendingAuthPromiseResolve)
				this.pendingAuthPromiseResolve();
			this.closeLoginIframe();
		}
	}

	protected openLoginIframe(){
		if(document.getElementById('loginContainer') === null) {
			const loginDivContainer = document.createElement('div');
			loginDivContainer.id = 'loginContainer';
			loginDivContainer.innerHTML = '<iframe src="/login?iframe=true"></iframe>';

			document.body.append(loginDivContainer);

			window.addEventListener('message', this.windowMessageListener);
		}
		return document.querySelector('#loginDivContainer iframe');
	}
	protected closeLoginIframe(){
		const loginDivContainer = document.getElementById('loginContainer');
		if(loginDivContainer){
			loginDivContainer.remove();
			window.removeEventListener('message', this.windowMessageListener);
		}
	}


	protected request(url: string, data: any = null, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', requireAuth = true, specificParameters: any = {}): any {
		let headers = specificParameters.headers ?? [];

		let promiseAuth = Promise.resolve();
		if (requireAuth) {
			if(!this.authCenter.isConnected()) {
				if(this.pendingAuthPromise == null){
					this.pendingAuthPromise = new Promise<void>((resolve, reject) => {
						this.pendingAuthPromiseResolve = resolve;
						this.openLoginIframe();
					});
				}
				promiseAuth = this.pendingAuthPromise;
			}
			promiseAuth = promiseAuth.then(()=>{
				headers['authorization'] = this.authCenter.getAuthHeader();
			});
		}

		specificParameters.headers = headers;

		return promiseAuth.then(()=>{
			return super.request(url, data, method, specificParameters);
		});
	}

}
